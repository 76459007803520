import { ElementType } from 'react';
import classNames from 'classnames';

import Typography, { Variant } from '@components/atoms/Typography';
import Message from 'types/Message';

import classes from './LargeHeading.module.scss';

type Props = {
  title: Message;
  secondTitle?: Message;
  subtitle?: Message;
  titleVariant?: Variant;
  titleRenderAs?: ElementType;
  secondTitleRenderAs?: ElementType;
  className?: string;
};

const LargeHeading = ({
  title,
  secondTitle,
  subtitle,
  className,
  titleVariant = 'h1',
  titleRenderAs = 'div',
  secondTitleRenderAs = 'div',
}: Props): JSX.Element => (
  <div className={classNames(classes.headerWrapper, className)}>
    <Typography variant={titleVariant} display="block" renderAs={titleRenderAs} className={classes.title}>
      {title}
    </Typography>
    {secondTitle && (
      <Typography
        variant="h1"
        display="block"
        renderAs={secondTitleRenderAs}
        weight="400"
        className={classes.secondTitle}
      >
        {secondTitle}
      </Typography>
    )}
    {subtitle && (
      <Typography
        variant="body1"
        display="block"
        renderAs="div"
        className={classNames(classes.subtitle, { [classes.hasSecondTitle]: secondTitle })}
      >
        {subtitle}
      </Typography>
    )}
  </div>
);

export default LargeHeading;
