// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resolvePath = <T extends Record<string, any>, R>(
  object: T,
  path = '',
  defaultValue: R | undefined = undefined
): R =>
  path
    .split(/[.[\]'"]/)
    .filter((p) => p)
    .reduce((o, p) => (o ? o[p] : defaultValue), object) as unknown as R;

export default resolvePath;
