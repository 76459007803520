import { useState, useEffect, ReactNode, useRef } from 'react';
import classNames from 'classnames';

import config from 'config';
import { Classes } from 'types/Classes';
import Message from 'types/Message';
import Arrow from 'assets/icons/arrow.svg';
import Button, { Props as ButtonProps } from '@components/atoms/Button';
import { FormattedMessage } from 'utils/intl';
import createStyleVariables from 'utils/createStyleVariables';
import useElementHeight from 'hooks/useElementHeight';

import messages from './ShowMoreContainer.messages';
import classes from './ShowMoreContainer.module.scss';

const { defaultGradientHeight } = config;

const defaultShowMore = (isExpanded: boolean) =>
  isExpanded ? <FormattedMessage {...messages.showLess} /> : <FormattedMessage {...messages.showMore} />;

type Props = {
  classes?: Classes<'root' | 'content' | 'button' | 'buttonContent'>;
  containerHeight?: string;
  gradientHeight?: number;
  forceShowAll?: boolean;
  buttonProps?: ButtonProps;
  showMore?: (isExpanded: boolean) => Message;
  children: ReactNode;
};

const ShowMoreContainer = ({
  classes: customClasses,
  containerHeight,
  gradientHeight = defaultGradientHeight,
  showMore = defaultShowMore,
  buttonProps,
  forceShowAll,
  children,
}: Props): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null);
  const contentHeight = useElementHeight(contentRef);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (forceShowAll) {
      setIsExpanded(false);
    }
  }, [forceShowAll]);

  return forceShowAll ? (
    <>{children}</>
  ) : (
    <div
      className={customClasses?.root}
      style={createStyleVariables({
        gradientHeight: isExpanded ? 0 : `${gradientHeight}px`,
        containerHeight: isExpanded ? `${contentHeight}px` : containerHeight,
      })}
    >
      <div className={classNames(classes.contentWrapper, customClasses?.content)}>
        <div className={classes.content}>
          <div ref={contentRef}>{children}</div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color="tertiary"
          variant="text"
          size="sm"
          classes={{
            root: classNames(classes.buttonRoot, customClasses?.button),
            content: classNames(classes.buttonContent, customClasses?.buttonContent),
          }}
          onClick={() => setIsExpanded(!isExpanded)}
          {...buttonProps}
        >
          {showMore(isExpanded)}
          <Arrow className={classNames(classes.buttonIcon, { [classes.expanded]: isExpanded })} />
        </Button>
      </div>
    </div>
  );
};

export default ShowMoreContainer;
