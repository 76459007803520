/* eslint-disable react/no-array-index-key */
import { Fragment, useContext } from 'react';

import { ConfigContext } from '@providers/ConfigProvider';
import Typography from '@components/atoms/Typography';
import { FormattedMessage } from 'utils/intl';

import { TextSection } from './SearchEngineText.types';
import classes from './SearchEngineText.module.scss';

type Props = {
  texts: TextSection[];
};

const SearchEngineText = ({ texts }: Props): JSX.Element => {
  const {
    contact: { appName, name, typeOfCompany },
  } = useContext(ConfigContext);
  const values = { appName, companyName: `${name} ${typeOfCompany}` };

  return texts.length ? (
    <Typography variant="caption2" renderAs="section" className={classes.wrapper}>
      {texts.map(({ header, blocks }, i) => (
        <article key={i} className={classes.article}>
          <FormattedMessage tagName="h3" {...header} values={values} />
          {blocks.map((block, j) => (
            <Fragment key={j}>
              {block.type === 'paragraph' && <FormattedMessage tagName="p" {...block.text} values={values} />}
              {block.type === 'list' && (
                <ul>
                  {block.items.map((item, k) => (
                    <FormattedMessage key={k} tagName="li" {...item} values={values} />
                  ))}
                </ul>
              )}
            </Fragment>
          ))}
        </article>
      ))}
    </Typography>
  ) : (
    <></>
  );
};

export default SearchEngineText;
