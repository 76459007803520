import SortValues from 'types/SortValues';
import SortOption from 'types/SortOption';
import SortOrder from 'types/SortOrder';
import { FormattedMessage } from 'utils/intl';

import messages from './sort.messages';

export enum SortId {
  Popularity,
  PriceAsc,
  PriceDesc,
}

export const sortIds = [SortId.Popularity, SortId.PriceAsc, SortId.PriceDesc];

export const sortValues: SortValues = {
  [SortId.Popularity]: [
    { field: 'is_available', direction: 'DESC' },
    { field: 'is_eol', direction: 'ASC' },
    { field: 'days_until_available', direction: 'ASC' },
    { field: 'price', direction: 'ASC' },
  ],
  [SortId.PriceAsc]: { field: 'price', direction: 'ASC' },
  [SortId.PriceDesc]: { field: 'price', direction: 'DESC' },
} as unknown as SortValues;

export const premiumSerwerSortValues: Partial<SortValues> = {
  [SortId.Popularity]: [
    { field: 'typ_obudowy_64', direction: 'ASC' },
    ...(sortValues[SortId.Popularity] as SortOrder[]),
  ],
} as unknown as Partial<SortValues>;

export const sortingOptions: SortOption[] = [
  {
    id: SortId.Popularity,
    label: <FormattedMessage {...messages.popularity} />,
  },
  {
    id: SortId.PriceAsc,
    label: <FormattedMessage {...messages.priceAsc} />,
  },
  {
    id: SortId.PriceDesc,
    label: <FormattedMessage {...messages.priceDesc} />,
  },
];
