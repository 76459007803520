/* eslint-disable react/destructuring-assignment */
import { ElementType, useContext } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import config from 'config';
import { commonMetatags } from 'consts/metatags';
import { ConfigContext } from '@providers/ConfigProvider';
import { PageContext } from '@providers/PageProvider';
import { FilesContext } from '@providers/FilesProvider';
import resolveImageUrl from 'utils/resolveImageUrl';

const { routes } = config;

const withMetatags =
  <T extends Record<string, unknown>>(
    Component: ElementType,
    { disableIndex = false, withCanonicalUrl = false }: { disableIndex?: boolean; withCanonicalUrl?: boolean } = {
      disableIndex: false,
      withCanonicalUrl: false,
    }
  ): ElementType =>
  (props: T): JSX.Element => {
    const router = useRouter();
    const { appUrl, contact } = useContext(ConfigContext);
    const { seoData } = useContext(PageContext);
    const { getShopImageConfigs } = useContext(FilesContext);
    const [logoOg] = getShopImageConfigs(['logo-og']);
    const logoOgUrl = logoOg ? resolveImageUrl(logoOg, { appUrl }) : '';
    const [pathWithoutHash] = router.asPath.split('#');
    const currentPath = router.asPath === routes.home.href ? '' : pathWithoutHash;

    return (
      <>
        <Head>
          {commonMetatags(
            contact,
            withCanonicalUrl ? `${appUrl}${currentPath}` : appUrl,
            (props.pageTitle as string) || seoData?.title,
            (props.metaDescription as string) || seoData?.metaDescription,
            logoOgUrl,
            disableIndex,
            withCanonicalUrl
          )}
        </Head>
        <Component {...props} />
      </>
    );
  };
export default withMetatags;
