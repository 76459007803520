import { getTrustedUsRoute } from 'apiRoutes/trustedUs';
import LargeHeading from '@components/atoms/LargeHeading';
import { FormattedMessage } from 'utils/intl';
import { getNumber } from 'utils/getNumber';
import { useClientSideProps } from 'hooks/useClientSideProps';
import useFetch from 'hooks/useFetch';

import TrustedUsView from './TrustedUs';
import messages from './TrustedUs.messages';
import classes from './TrustedUs.module.scss';

type Props = {
  page: string;
  limit: 12 | 18;
};

const TrustedUs = ({ page, limit }: Props): JSX.Element => {
  const [getTrustedUs] = useFetch(getTrustedUsRoute);

  const {
    isLoading,
    data: { trustedUsData },
  } = useClientSideProps({
    trustedUsData: () => getTrustedUs({ page }),
  });

  const logos = trustedUsData?.data?.trustedUsLogos || [];
  const numbers = trustedUsData?.data?.numbers || [];

  return logos.length || isLoading ? (
    <section className={classes.trustedUs}>
      <div className={classes.headerWrapper}>
        <LargeHeading
          title={<FormattedMessage {...messages.mainHeader} values={{ clients: getNumber(numbers, 'CLIENTS') }} />}
          subtitle={<FormattedMessage {...messages.mainDescription} />}
        />
      </div>
      <TrustedUsView logos={logos} limit={limit} isLoading={isLoading} />
    </section>
  ) : (
    <></>
  );
};

export default TrustedUs;
