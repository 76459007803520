import Image from '@next/image';
import classNames from 'classnames';

import { TrustedUs } from 'types/models/TrustedUs';
import SkeletonLoader from '@components/atoms/SkeletonLoader';
import ShowMoreContainer from '@components/ShowMoreContainer';
import { FormattedMessage } from 'utils/intl';

import messages from './TrustedUs.messages';
import classes from './TrustedUs.module.scss';

type Props = {
  logos: Pick<TrustedUs, 'id' | 'image' | 'url' | 'name'>[];
  limit: 12 | 18;
  isLoading?: boolean;
};

const TrustedUsView = ({ logos, limit, isLoading }: Props): JSX.Element => (
  <ShowMoreContainer
    buttonProps={{
      color: 'secondary',
      variant: 'outlined',
      size: 'md',
    }}
    classes={{
      root: classNames(classes.wrapper, classes[`wrapper${limit}`]),
      content: classes.logosContent,
      button: classes.button,
      buttonContent: classes.buttonContent,
    }}
    showMore={(isExpanded) => <FormattedMessage {...(isExpanded ? messages.showLess : messages.showMore)} />}
  >
    {!isLoading ? (
      <div className={classes.logos}>
        {logos.map(({ id, image, name }) => (
          <div key={id} className={classes.logo}>
            <Image
              objectFit="contain"
              title={name}
              alt={name}
              resolveConfig={{
                preset: 'customer_logo',
                productName: 'customer_logo',
                extension: image.extension,
                name: image.basename,
                description: name,
              }}
            />
          </div>
        ))}
      </div>
    ) : (
      <SkeletonLoader className={classes.skeleton} iconWidth={250} />
    )}
  </ShowMoreContainer>
);

export default TrustedUsView;
